import { Alert } from "@hagerty/react-components";
import styles from "./EnthusiastPolicyAlert.module.scss";
import { Link } from "../Link";
import { Button } from "../Button";

export const EnthusiastPolicyAlert = () => {
  return (
    <Alert
      classNames={styles.root}
      alertType="info"
      messageText={
        <>
          <div className={styles.message}>
            <span className={styles.boldMessage}>
              You are participating in a Hagerty beta program.{" "}
            </span>
            In order to pay your bill, edit your policy, or file a claim please
            call{" "}
            <Link className={styles.link} phoneNumber="8888888888">
              888-888-8888
            </Link>
            .
          </div>
          <Button href="tel:8888888888" variant="primary-black">
            Call now
          </Button>
        </>
      }
    />
  );
};
