import { StateName } from "shared";
import { Dialog, DialogButton, DialogButtonGroup } from "../../Dialog";
import { Link } from "../../Link";
import { TagAction } from "../../../types/Tag.types";
import { mt } from "../../../utils/tracking";
import { useEffect } from "react";

const viewOrModifyTagTaction: TagAction = {
  actionType: "link",
  actionName: "view and modify your coverages here",
};

const title = "This policy doesn't have an ID card";

type CompOnlyDialogProps = {
  stateName: StateName;
  managePolicyUrl?: string;
  onClose: () => void;
};

export const CompOnlyDialog = ({
  stateName,
  managePolicyUrl,
  onClose,
}: CompOnlyDialogProps) => {
  useEffect(() => mt.trackPopup("comp only"), []);

  const closeDialog = () => {
    mt.trackPopupClose();
    onClose();
  };

  return (
    <Dialog
      title={title}
      footer={
        <DialogButtonGroup>
          <DialogButton onClick={closeDialog} variant="primary" aria-hidden>
            Got it
          </DialogButton>
        </DialogButtonGroup>
      }
      onDismiss={closeDialog}
    >
      <p>
        You only have Comprehensive coverage for your {stateName} policy. This
        doesn’t cover damages while on the road, so an ID card isn’t issued.
        {managePolicyUrl && (
          <>
            You can{" "}
            <Link
              href={managePolicyUrl}
              tagAction={viewOrModifyTagTaction}
              ariaLabel="view and modify your coverages"
            >
              view and modify your coverages here
            </Link>
            {"."}
          </>
        )}
      </p>
    </Dialog>
  );
};
