import { StateName } from ".";

type Headings = {
  heading: string;
  accessibleHeading: string;
};

export const partnerMap: Record<string, Headings> = {
  "state-farm": {
    heading: "State Farm Classic+®",
    accessibleHeading: "State Farm Classic Policy",
  },
};

export function getDefaultPartnerHeadings(stateName: StateName): Headings {
  const heading = `${stateName} Policy`;
  return {
    heading,
    accessibleHeading: heading,
  };
}

export const getPartnerHeadings = (
  partner: string | undefined = "",
  stateName: StateName,
  isEnthusiastFlagOn: boolean
): Headings => {
  const headings = partnerMap[partner] ?? getDefaultPartnerHeadings(stateName);

  if (isEnthusiastFlagOn) {
    return {
      heading: "[Enthusiast+] policy",
      accessibleHeading: headings.accessibleHeading,
    };
  }

  return headings;
};
