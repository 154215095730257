import { Tile } from "../Tile";
import { ReactComponent as DownChevron } from "./img/chevron-down.svg";
import { Claims, IdCard, Edit, Documents } from "../../svg";
import { useUrls } from "../../app/Providers/AppContext";
import { getStateName } from "shared";
import { getVehiclesLabel } from "../../utils/vehicleUtils";
import styles from "./InsuranceTiles.module.scss";
import { TagAction } from "../../types/Tag.types";
import { useDialog } from "../Dialog";
import { CompOnlyDialog } from "./CompOnlyDialog";
import { Policy } from "@hagerty/self-service-components/api/types";
import { Popover } from "@hagerty/self-service-components";
import { useEffect, useRef, useState } from "react";
import { Card } from "../Card";
import { useDownloadIdCard } from "./useDownloadIdCard";
import { getFileClaimUrl } from "../../utils/urlUtils";
import { DownloadIdCardErrorAlert } from "./DownloadIdCardErrorAlert";
import { Link } from "../Link";
import clsx from "clsx";
import { routes } from "../../routing/routes";

type InsuranceTilesProps = {
  policy: Policy;
  // Temporary prop to determine if the policy source is Apex, will be replaced when support to API will be added
  isApexPolicy?: boolean;
};

const idTagAction: TagAction = {
  actionName: "Insurance ID",
  actionType: "button",
};
const policyDocumentsTagAction: TagAction = {
  actionName: "Policy documents",
  actionType: "link",
};
const managePolicyTagAction: TagAction = {
  actionName: "Manage policy",
  actionType: "link",
};
const fileClaimTagAction: TagAction = {
  actionType: "link",
  actionName: "File a claim",
};
const claimStatusTagAction: TagAction = {
  actionType: "link",
  actionName: "Claim status",
};

export const InsuranceTiles = ({
  policy,
  isApexPolicy,
}: InsuranceTilesProps) => {
  const [redirectClaims, setRedirectClaims] = useState(false);
  const [redirectPolicyDocument, setRedirectPolicyDocument] = useState(false);
  const triggerRef = useRef<HTMLElement>(null);
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const urls = useUrls();
  const vehicleNamesLabel = getVehiclesLabel(policy.vehicles);
  const compOnlyDialog = useDialog();
  const [isErrorAlertOpen, setIsErrorAlertOpen] = useState(false);
  const { policyNumber, partner, country, state } = policy;
  const {
    download: downloadIdCard,
    isDownloading,
    error: downloadIdCardError,
  } = useDownloadIdCard(policyNumber, country);

  const stateName = getStateName(state);
  const isStateFarm = partner === "state-farm";

  const fileClaimUrl = getFileClaimUrl(policy, urls);
  const claimStatusUrl = urls.insurance.fileClaim(policyNumber, country);

  const managePolicyUrl = routes.policyDetails(policyNumber);

  const hidePopover = () => {
    setIsPopoverOpen(false);
    triggerRef?.current?.blur();
  };

  const onClickClaimsLink = () => {
    hidePopover();
    setRedirectClaims(true);
  };

  useEffect(() => {
    setIsErrorAlertOpen(downloadIdCardError != undefined);
  }, [downloadIdCardError]);

  return (
    <>
      <div
        className={clsx(
          styles.policyTiles,
          isApexPolicy && styles.policyTilesRow
        )}
      >
        <Tile
          icon={<img src={IdCard} alt="Insurance ID" aria-hidden="true" />}
          isLoading={isDownloading}
          className={styles.tile}
          cta="Insurance ID"
          ariaLabel={`Access Insurance ID for your ${vehicleNamesLabel} on policy ${policyNumber}`}
          tagAction={idTagAction}
          onClick={() =>
            policy.hasIdCard ? downloadIdCard() : compOnlyDialog.open()
          }
        />

        {isApexPolicy && (
          <Tile
            icon={
              <img src={Documents} alt="Declaration page" aria-hidden="true" />
            }
            className={styles.tile}
            cta="Declaration Page"
            ariaLabel={`Declaration page for policy ${policyNumber}`}
            onClick={() => console.log("Declaration page clicked")}
          />
        )}

        {country === "USA" && !isApexPolicy && (
          <>
            <Tile
              icon={<img src={Claims} alt="Manage claims" aria-hidden="true" />}
              className={styles.tile}
              cta="Claims"
              ariaLabel={`Manage claims for your ${vehicleNamesLabel} on policy ${policyNumber}`}
              onClick={() => setIsPopoverOpen(!isPopoverOpen)}
              isLoading={redirectClaims}
              ctaIcon={<DownChevron />}
              ref={triggerRef}
            />
            <Popover
              triggerRef={triggerRef}
              onClose={() => setTimeout(hidePopover)}
              isOpen={isPopoverOpen}
              placement={"bottom start"}
              className={styles.popover}
              restoreFocus={false}
            >
              <Card className={styles.card}>
                <Link
                  tagAction={fileClaimTagAction}
                  onClick={onClickClaimsLink}
                  href={fileClaimUrl}
                  isLoading={false}
                >
                  File a claim
                </Link>
                <Link
                  tagAction={claimStatusTagAction}
                  onClick={onClickClaimsLink}
                  href={claimStatusUrl}
                >
                  Claim status
                </Link>
              </Card>
            </Popover>
          </>
        )}
        {country === "CANADA" && !isApexPolicy && (
          <Tile
            icon={<img src={Claims} alt="File a claim" aria-hidden="true" />}
            className={styles.tile}
            cta="File a claim"
            ariaLabel={`File a claim for your ${vehicleNamesLabel} on policy ${policyNumber}`}
            href={fileClaimUrl}
            tagAction={fileClaimTagAction}
            onClick={onClickClaimsLink}
            isLoading={redirectClaims}
          />
        )}

        {!isApexPolicy && (
          <Tile
            icon={
              <img src={Documents} alt="Policy documents" aria-hidden="true" />
            }
            className={styles.tile}
            cta="Policy documents"
            ariaLabel={`Policy documents for policy ${policyNumber}`}
            href={urls.insurance.viewPolicy(policyNumber, country, isStateFarm)}
            tagAction={policyDocumentsTagAction}
            onClick={() => setRedirectPolicyDocument(true)}
            isLoading={redirectPolicyDocument}
          />
        )}

        {!isStateFarm && !isApexPolicy && (
          <Tile
            icon={<img src={Edit} alt="Manage policy" aria-hidden="true" />}
            className={styles.tile}
            cta="Manage policy"
            ariaLabel={`Manage policy ${policyNumber} for your ${vehicleNamesLabel}`}
            href={managePolicyUrl}
            tagAction={managePolicyTagAction}
          />
        )}
      </div>

      {compOnlyDialog.isOpen && (
        <CompOnlyDialog
          stateName={stateName}
          managePolicyUrl={managePolicyUrl}
          onClose={compOnlyDialog.close}
        />
      )}

      {isErrorAlertOpen && (
        <DownloadIdCardErrorAlert onClose={() => setIsErrorAlertOpen(false)} />
      )}
    </>
  );
};
