import { Layout } from "../Layout/Layout";
import styles from "./PolicyDetails.module.scss";
import { VehiclesListCard } from "./VehiclesListCard/VehiclesListCard";
import { useParams } from "react-router-dom";
import { DriversListCard } from "./DriversListCard/DriversListCard";
import { CoveragesListCard } from "./CoveragesListCard/CoveragesListCard";
import { NotFound } from "../../not-found";
import { usePolicy } from "./usePolicy";
import { getStateName } from "shared";
import { PolicyTiles } from "./PolicyTiles/PolicyTiles";
import { PolicyInfo } from "./PolicyInfo/PolicyInfo";
import { InsuranceContextProvider } from "../Insurance/InsuranceContext";

export const PolicyDetails = () => {
  const { policyNumber } = useParams();
  const policy = usePolicy(policyNumber as string);

  if (!policy) {
    return <NotFound />;
  }

  return (
    <Layout
      title={`${getStateName(policy.state)} policy`}
      subtitle="Review your policy info or make changes. Recent policy activity may not be reflected."
    >
      <InsuranceContextProvider policies={[policy]}>
        <PolicyTiles policy={policy} className={styles.spacing} />
        <div className={styles.sections}>
          <PolicyInfo policy={policy} />
          <VehiclesListCard policy={policy} />
          <DriversListCard policy={policy} />
          <CoveragesListCard policy={policy} />
        </div>
      </InsuranceContextProvider>
    </Layout>
  );
};
