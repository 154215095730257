import { Button, ListCard } from "@hagerty/self-service-components";
import { useFeatureFlag, useUrls } from "../../../app/Providers/AppContext";
import { TagAction } from "../../../types/Tag.types";
import { Vehicle } from "../../Insurance/Vehicle/Vehicle";
import { Policy } from "@hagerty/self-service-components/api/types";
import styles from "./VehicleListCard.module.scss";
import { mt } from "../../../utils/tracking";
import { useState } from "react";
import clsx from "clsx";

const addVehicleTagAction: TagAction = {
  actionName: "Add vehicle",
  actionType: "button",
};

type VehiclesListCardProps = {
  policy: Policy;
  className?: string;
  // Temporary prop to determine if the policy source is Apex, will be replaced when support to API will be added
  isApexPolicy?: boolean;
};

export const VehiclesListCard = ({
  policy,
  className,
  isApexPolicy,
}: VehiclesListCardProps) => {
  const [redirectAddVehicle, setRedirectAddVehicle] = useState(false);
  const { ADD_VEHICLE } = useFeatureFlag();
  const urls = useUrls();
  const { policyNumber, vehicles, partner, country } = policy;
  const shouldRenderAddVehicle = partner !== "state-farm" && !isApexPolicy;

  const addVehicleOnClick = () => {
    mt.trackAction(addVehicleTagAction);
    setRedirectAddVehicle(true);
  };

  return (
    <ListCard className={clsx(styles.listCard, className)}>
      <ListCard.Header
        aria-label={`Insured vehicles for policy ${policyNumber}`}
      >
        {isApexPolicy ? "Insured vehicles" : "Vehicles"}
      </ListCard.Header>
      <ListCard.ExpandableRows
        maxRowCount={5}
        viewRowsLabel="View vehicles"
        hideRowsLabel="Hide vehicles"
        hasFooter={shouldRenderAddVehicle}
      >
        {vehicles
          .sort((a, b) => (a.year < b.year ? -1 : a.year > b.year ? 1 : 0))
          .map((vehicle, index) => (
            <ListCard.Row key={index}>
              <Vehicle
                policy={policy}
                vehicle={vehicle}
                isApexPolicy={isApexPolicy}
              />
            </ListCard.Row>
          ))}
      </ListCard.ExpandableRows>
      {shouldRenderAddVehicle && (
        <ListCard.Footer>
          <Button
            className={styles.addVehicleButton}
            as="a"
            variant="outlined"
            size="s"
            aria-label={`Add vehicle to policy ${policyNumber}`}
            href={urls.insurance.addVehicle(
              policyNumber,
              country,
              ADD_VEHICLE.treatment
            )}
            onClick={addVehicleOnClick}
            isLoading={redirectAddVehicle}
          >
            Add vehicle
          </Button>
        </ListCard.Footer>
      )}
    </ListCard>
  );
};
