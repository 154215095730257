import { useEffect } from "react";
import { useFetchApi } from "../../hooks/useFetchApi";
import { useLogHasOutstandingBalance } from "../../app/Providers/LogOnReadyContext";
import { Billing } from "@hagerty/self-service-components/api/types";

export const useBillingPayment = () => {
  const logHasOutstandingBalance = useLogHasOutstandingBalance();
  const { data: billing } = useFetchApi<Billing>("billing", 2);

  const { outstandingBalance } = billing;
  const hasOutstandingBalance = outstandingBalance > 0;

  useEffect(() => {
    logHasOutstandingBalance(hasOutstandingBalance);
  }, [hasOutstandingBalance, logHasOutstandingBalance]);

  return billing;
};
