import { InsurancePolicy } from "./InsurancePolicy";
import { NoInsurance } from "./NoInsurance";
import { NoInsuranceModal } from "./NoInsuranceModal";
import { ConnectPolicy } from "./ConnectPolicy";
import {
  useIsStateFarmPartitioned,
  useIsSso,
  useFeatureFlag,
} from "../../app/Providers/AppContext";
import { useInsurance } from "./useInsurance";
import { InsuranceContextProvider } from "./InsuranceContext";
import { Fragment } from "react";

export const Insurance = () => {
  const { ENTHUSIAST } = useFeatureFlag();
  const policies = useInsurance();
  const isSso = useIsSso();
  const hasStateFarmPartition = useIsStateFarmPartitioned();
  const isStateFarmSsoLogin = hasStateFarmPartition && isSso;
  const isEnthusiastFlagOn = ENTHUSIAST.treatment === "on";

  return policies.length === 0 ? (
    <>
      <NoInsurance
        isStateFarmPartner={hasStateFarmPartition}
        isStateFarmSso={isStateFarmSsoLogin}
      />
      {/* Do not display NoInsuranceModal for State Farm SSO partner login*/}
      {!isStateFarmSsoLogin && <NoInsuranceModal />}
    </>
  ) : (
    <InsuranceContextProvider policies={policies}>
      {policies.map((policy) => (
        <Fragment key={policy.policyNumber}>
          {isEnthusiastFlagOn && (
            <InsurancePolicy policy={policy} isApexPolicy />
          )}
          <InsurancePolicy policy={policy} />
        </Fragment>
      ))}
      {!hasStateFarmPartition && <ConnectPolicy />}
    </InsuranceContextProvider>
  );
};
