import { Country } from "@hagerty/self-service-components/api/types";
import { useCallback, useState } from "react";
import * as Sentry from "@sentry/react";
import { useUrls } from "../../app/Providers/AppContext";

export type IdCardUrl = {
  url: string;
};

export const useDownloadIdCard = (policyNumber: string, country: Country) => {
  const urls = useUrls();
  const [isDownloading, setIsDownloading] = useState(false);
  const [error, setError] = useState<Error>();

  const download = useCallback(async () => {
    setIsDownloading(true);
    const response = await fetch(
      urls.insurance.getCardUrl(window.location.origin, country, policyNumber),
      {
        headers: {
          "content-type": "application/json",
        },
      }
    );
    if (!response || !response.ok) {
      setError(new Error("Couldn't fetch id cards url"));
      setIsDownloading(false);
      return;
    }
    const url = await response.text();
    try {
      const fetchResponse = await fetch(url);
      const blob = await fetchResponse.blob();
      const objectUrl = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = objectUrl;
      link.target = "_blank";
      link.download = `${policyNumber}_${country}_id_card.pdf`;
      document.body.appendChild(link);
      link.click();
      link.remove();
      setError(undefined);
    } catch (err) {
      setError(new Error("Couldn't launch id cards url"));
      Sentry.captureException(err, { extra: { url } });
    } finally {
      setIsDownloading(false);
    }
  }, [policyNumber, country]);

  return { download, isDownloading, error };
};
