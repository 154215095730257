import {
  ExclamationMarkIcon,
  ListCard,
} from "@hagerty/self-service-components";
import { phoneNumbers } from "shared";
import styles from "./DriversListCard.module.scss";
import { Link } from "../../Link";
import { Customer } from "@hagerty/self-service-components/api/types/Customer.types";
import { getCustomerAge } from "../../../utils/getCustomerAge";
import { Policy } from "@hagerty/self-service-components/api/types";

export type DriversListCardProps = {
  policy: Policy;
};

const insuredType = {
  PRI: "Primary insured",
  SEC: "Secondary insured",
  DEP: "Dependent",
  OTHER: "Other",
};

const getInsured = (driver: Customer) => {
  if (!driver.DriverInfo.IsVehicleOperator) {
    return "Other";
  }

  return (
    insuredType[driver.CustomerType as keyof typeof insuredType] ||
    insuredType.OTHER
  );
};

export const DriversListCard = ({ policy }: DriversListCardProps) => {
  const { household } = policy;
  const { Customers } = household;
  return (
    <ListCard>
      <ListCard.Header>Drivers</ListCard.Header>
      <ListCard.ExpandableRows
        maxRowCount={5}
        viewRowsLabel="View drivers"
        hideRowsLabel="Hide drivers"
        hasFooter
      >
        {Customers.map((driver, index) => (
          <ListCard.Row key={index}>
            <div className={styles.column}>
              <div className={styles.name}>
                {driver.Name.Prefix} {driver.Name.First} {driver.Name.Last}{" "}
                {driver.Name.Suffix}
              </div>
              <div className={styles.details}>
                {getInsured(driver)}, Age {getCustomerAge(driver)}
              </div>
            </div>
          </ListCard.Row>
        ))}
      </ListCard.ExpandableRows>
      <ListCard.Footer>
        <div className={styles.footer}>
          <ExclamationMarkIcon />
          <div>
            Please call{" "}
            <Link phoneNumber={phoneNumbers.auto}>{phoneNumbers.auto}</Link> to
            add or edit drivers.
          </div>
        </div>
      </ListCard.Footer>
    </ListCard>
  );
};
