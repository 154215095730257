import { InsuranceLayout } from "./InsuranceLayout";
import { useScrollToHashOnLoad } from "../../hooks/useScrollToHashOnLoad";
import { PolicyProps } from "../Policy/Policy.types";
import { InsuranceTiles } from "./InsuranceTiles";
import { VehiclesListCard } from "../PolicyDetails/VehiclesListCard/VehiclesListCard";
import styles from "./InsurancePolicy.module.scss";

/** Renders the "Hagerty Insurance Policy" region */
export const InsurancePolicy = ({ policy, isApexPolicy }: PolicyProps) => {
  useScrollToHashOnLoad("insurance");

  return (
    <InsuranceLayout policy={policy} isApexPolicy={isApexPolicy}>
      <InsuranceTiles policy={policy} isApexPolicy={isApexPolicy} />
      <VehiclesListCard
        policy={policy}
        className={styles.spacing}
        isApexPolicy={isApexPolicy}
      />
    </InsuranceLayout>
  );
};
