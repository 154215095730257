import { useLogHasESignDocuments } from "../../app/Providers/LogOnReadyContext";
import {
  Alerts as AlertsResponse,
  getStateName,
  phoneNumbers,
  URLs,
} from "shared";
import { AlertLevel } from "../Alert";
import { UseESign } from "./useESign";
import { useUrls } from "../../app/Providers/AppContext";
import { useFetchApi } from "../../hooks/useFetchApi";
import { TagAlert } from "../../types/Tag.types";
import { useMemo, useRef } from "react";
import { Bill } from "@hagerty/self-service-components/api/types";

import { removeVersionFromPolicyNumber } from "../../utils/removeVersionFromPolicyNumber";
import { mt } from "../../utils/tracking";
import { ActionConfig } from "./ActionConfig.types";

const HDCLevels = ["HDC1DOT0", "HDC1DOT5"];
const signLabel = "Sign documents";
const PleaseCallUs = () => <span aria-hidden="true">Please call us at:</span>;

function getCallHagertyActionConfig(phone: string): ActionConfig {
  return {
    ariaLabel: `Call Hagerty at ${phone}`,
    href: `tel:+${phone}`,
    text: phone,
    tagAction: {
      actionName: "call_us",
      actionType: "link",
    },
  };
}

const getPayBillActionConfig = ({
  urls,
  bill,
}: {
  urls: URLs;
  bill?: Bill;
}): ActionConfig => {
  const buttonText = "Pay now";
  return {
    ariaLabel: "Pay your bill",
    text: buttonText,
    href: bill?.paymentUrl ?? urls.billing.managePayments,
    showLoader: true,
    tagAction: {
      actionName: buttonText,
      actionType: "link",
    },
  };
};

export type Alert = {
  level: AlertLevel;

  /** Title, displayed in bold when there's a single alert */
  title: React.ReactNode;

  /** Optional message displayed after the title. Not bold. */
  message?: React.ReactNode;

  /** Optional button or link config. Displays on the right. */
  actionConfig?: ActionConfig;
};

// Fetch alerts on load. Returns an array of Alerts to normalize the data structure for rendering alerts
export function useAlerts(sendESignDocument: UseESign["send"]): Alert[] {
  const trackedAlerts = useRef<string[]>([]);
  const urls = useUrls();
  const logHasESignDocuments = useLogHasESignDocuments();

  // Prevent alert from being logged multiple times due to subsequent renders
  const trackAlert = ({ alertType, alertDetails }: TagAlert) => {
    if (!trackedAlerts.current.includes(alertType)) {
      mt.trackAlert({ alertType, alertDetails });
      trackedAlerts.current = [...trackedAlerts.current, alertType];
    }
  };

  // We want to fetch those alerts only once, that is the reason why I memoize it. This hook is re-rending every time when something has changed
  // on useESign hook, so it was causing issues with blinking/hidden esign modal. In addition, /alerts request is a bit heavy (takes some time).
  // We also do not refresh those alerts, so I decided to use memoization to fix issue with blinking modal
  const { data: alertsResponse } = useMemo(
    () => useFetchApi<AlertsResponse>("alerts", 2),
    []
  );

  const {
    bills = [],
    insuranceAutopayFailed = [],
    insuranceBillPastDue = [],
    hdcAutopayFailed,
    hdcBillPastDue,
    eSignDocuments = [],
  } = alertsResponse;

  logHasESignDocuments(eSignDocuments.length > 0);

  const alerts: Alert[] = [];

  if (hdcAutopayFailed) {
    const title = "Payment for Drivers Club membership failed to process.";
    const phone = phoneNumbers.hdc;

    trackAlert({
      alertType: title,
      alertDetails: `Please call us at: ${phone}`,
    });

    alerts.push({
      title,
      message: <PleaseCallUs />,
      actionConfig: getCallHagertyActionConfig(phone),
      level: "danger",
    });
  }

  if (hdcBillPastDue) {
    const title = "Payment for Drivers Club membership is past due.";
    const message = "Please make a payment.";
    const matchingBill = bills.find(({ category }) =>
      HDCLevels.includes(category)
    );

    trackAlert({ alertType: title, alertDetails: message });

    alerts.push({
      title,
      message,
      actionConfig: getPayBillActionConfig({
        urls,
        bill: matchingBill,
      }),
      level: "danger",
    });
  }

  insuranceAutopayFailed.forEach(({ country, policyNumber, state }) => {
    const title = `Auto pay for ${getStateName(
      state
    )} policy #${removeVersionFromPolicyNumber(
      policyNumber
    )} failed to process.`;
    const phone = phoneNumbers.insurancePaymentError(country);

    trackAlert({
      alertType: title,
      alertDetails: `Please call us at: ${phone}`,
    });

    alerts.push({
      title,
      message: <PleaseCallUs />,
      actionConfig: getCallHagertyActionConfig(phone),
      level: "danger",
    });
  });

  insuranceBillPastDue.forEach(({ policyNumber, state }) => {
    const title = `Bill for ${getStateName(
      state
    )} policy #${removeVersionFromPolicyNumber(policyNumber)} is past due.`;
    const message = "Please make a payment.";
    const matchingBill = bills.find(
      ({ formattedProductNumber }) => formattedProductNumber === policyNumber
    );

    trackAlert({ alertType: title, alertDetails: message });

    alerts.push({
      title,
      message,
      actionConfig: getPayBillActionConfig({
        urls,
        bill: matchingBill,
      }),
      level: "danger",
    });
  });

  eSignDocuments.forEach((eSignDocument) => {
    const stateName = getStateName(eSignDocument.state);
    const title = "Signature required.";
    const message = `Please sign the documents to finalize the ${stateName} policy change.`;

    trackAlert({ alertType: title, alertDetails: message });

    alerts.push({
      title,
      message,
      actionConfig: {
        text: signLabel,
        ariaLabel: `E-Sign the ${stateName} policy change documents.`,
        onClick: () => sendESignDocument(eSignDocument),
        tagAction: {
          actionName: signLabel,
          actionType: "button",
        },
      },
      level: "warning",
    });
  });

  return alerts;
}
