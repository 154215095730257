import { Bills } from "@hagerty/self-service-components/payments";
import { useBillingPayment } from "./useBillingPayment";
import styles from "./BillingPayment.module.scss";
import { useFeatureFlag } from "../../app/Providers/AppContext";

export const heading = "Upcoming Bills";

export const BillingPayment = () => {
  const { ENTHUSIAST } = useFeatureFlag();
  const { bills } = useBillingPayment();
  const isEnthusiastFlagOn = ENTHUSIAST.treatment === "on";

  return (
    <div className={styles.container}>
      <Bills
        isLoading={false}
        bills={bills}
        returnButtonLabel="Return to Account Dashboard"
        returnUrl={window.location.href}
        isDirectContactRequired={isEnthusiastFlagOn}
      />
    </div>
  );
};
