import { useRef } from "react";
import { Highlight } from "../Highlight";
import { useActiveSection } from "../../hooks/useActiveSection";
import styles from "./InsuranceLayout.module.scss";
import { Section } from "../Section";
import { SubHeader } from "../SubHeader";
import { SectionHeader } from "../SectionHeader";
import { getPartnerHeadings, getStateName } from "shared";
import { getVehiclesLabel } from "../../utils/vehicleUtils";
import { PartnerLogo } from "../PartnerLogo/PartnerLogo";
import { ContactBroker } from "./ContactBroker";
import { EffectiveFrom } from "../EffectiveFrom/EffectiveFrom";
import { Policy } from "@hagerty/self-service-components/api/types";
import { formatPolicyNumber } from "../../utils/formatPolicyNumber";
import { EnthusiastPolicyAlert } from "./EnthusiastPolicyAlert";

type InsuranceLayoutProps = {
  children: React.ReactNode;
  policy: Policy;
  // Temporary prop to determine if the policy source is Apex, will be replaced when support to API will be added
  isApexPolicy?: boolean;
};

export function InsuranceLayout({
  children,
  policy,
  isApexPolicy = false,
}: InsuranceLayoutProps) {
  const {
    policyNumber,
    vehicles,
    effectiveDate,
    expirationDate,
    state,
    partner,
  } = policy;

  const headerRef = useRef<HTMLHeadingElement | null>(null);
  useActiveSection(headerRef, "insurance");
  const vehicleNamesLabel = getVehiclesLabel(vehicles);

  const stateName = getStateName(state);
  const { heading, accessibleHeading } = getPartnerHeadings(
    partner,
    stateName,
    isApexPolicy
  );
  const ariaLabel = `${accessibleHeading} ${policyNumber} for ${vehicleNamesLabel}`;

  const broker = policy.broker;

  return (
    <Section ariaLabel={accessibleHeading} partner={partner}>
      {partner && <PartnerLogo partner={partner} />}
      <SectionHeader
        heading={heading}
        headerRef={headerRef}
        ariaLabel={ariaLabel}
        id="insurance"
      >
        {policyNumber && (
          <Highlight className={styles.highlight} ariaLabel="Policy number">
            #{formatPolicyNumber(policyNumber)}
          </Highlight>
        )}
      </SectionHeader>

      <SubHeader
        elements={[
          <EffectiveFrom
            effectiveDate={effectiveDate}
            expirationDate={expirationDate}
          />,
        ]}
      />

      {isApexPolicy && <EnthusiastPolicyAlert />}
      {broker && !isApexPolicy && (
        <ContactBroker partner={partner} broker={broker} />
      )}

      <dl className="grid grid-wrap collapsed-gutters login-data-table_row">
        {children}
      </dl>
    </Section>
  );
}
